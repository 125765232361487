import React, {useEffect, useReducer, useState} from 'react';
import {ContentAdDesktop} from 'app/styles/content';
import StreamContainer from 'app/components/stream/streamContainer';
import {useSelector} from 'react-redux';
import {cupFilters} from 'app/components/stream/streamFilter.const';
import {Helmet} from 'react-helmet-async';
import {object, string} from 'prop-types';
import {fetchCupNews, overwriteNews} from 'app/routes/cup/CupPageActions';
import {useInfiniteScrollComponentState} from 'app/hooks/useInfiniteScroll';
import {StreamFilterFab} from 'app/components/floatingAction/StreamFilterFab';
import {EditFabContainer} from 'app/components/floatingAction/EditFab';
import {getPermission} from 'app/services/api';
import {selectAuthState} from 'app/hooks/reduxCreateSelectorHooks';
import {useFetchData, useRestoreData} from 'app/hooks/dataHooks';
import {useParams} from 'react-router';
import {CupNewsPageReducer} from 'app/routes/cup/CupPageReducer';

const streamRegex = /\/cup\/[a-z0-9-]+(\/season\/\d+-\d+)?\/?/;

const CupNewsPage = ({defaultMeta, cupId, competitionSeasonId, dispatchRedux}) => {
  const {restoredData} = useRestoreData('CupNewsPage');
  const {cupSlug, seasonSlug, categories} = useParams();
  const initialData = {
    isFetching: false,
    items: [],
    nextUrl: '',
    categoryFilter: categories,
    error: null,
  };

  const initialState = restoredData ?? initialData;
  const [state, dispatch] = useReducer(CupNewsPageReducer, initialState);

  const loadData = (reloading, reset) => {
    const componentState = reset ? initialData : state;
    return fetchCupNews(cupSlug, seasonSlug, categories, reloading, dispatchRedux)(dispatch, componentState);
  };

  const isAuthenticated = useSelector(selectAuthState);
  const [isAuthorised, setIsAuthorised] = useState(false);
  const {items, isFetching, nextUrl, error, categoryFilter} = state;
  useInfiniteScrollComponentState(loadData, items.length);
  useFetchData(state, loadData, 'CupNewsPage', true);

  useEffect(() => {
    // Always load new data when switching between filters
    if (categoryFilter !== categories) {
      dispatch(overwriteNews(initialData));
      loadData(false, true);
    }
  }, [categories]);

  const filter = cupFilters.find(filter => filter.param === categories);
  const title = filter ? `${filter.value} - ` : '';
  const meta = [{name: 'description', content: title + defaultMeta}];

  useEffect(() => {
    if (cupId && !isAuthorised) {
      getPermission('competition', cupId)
        .then(response => setIsAuthorised(response.data?.edit))
        .catch(error => {
          if (error.status === 401) {
            setIsAuthorised(false);
          }
        });
    } else if (!isAuthenticated && isAuthorised) {
      setIsAuthorised(false);
    }
  }, [cupId, isAuthenticated]);

  const editBtn =
    isAuthorised && competitionSeasonId ? (
      <EditFabContainer
        secondary
        url={`${process.env.ADMIN_URL}/fupa/admin/index.php?page=liga_turnier_spieltag&liga=${competitionSeasonId}`}
      />
    ) : null;

  return (
    <ContentAdDesktop
      bgColor='none'
      top='7.125rem'
      fabBtn={
        <>
          {editBtn}
          <StreamFilterFab filter={cupFilters} regex={streamRegex} />
        </>
      }>
      <Helmet title={title} meta={meta} />
      <StreamContainer isFetching={isFetching} nextPage={nextUrl} error={Boolean(error)} stream={items} />
    </ContentAdDesktop>
  );
};

CupNewsPage.propTypes = {
  match: object.isRequired,
  defaultMeta: string.isRequired,
};

export default CupNewsPage;
