import {api, fetchNextApiCall} from 'app/services/api';
import {changeHeaderEntity, changeHeaderFollowing, changeHeaderText} from 'app/actions/headerActions';
import {catchDataError, checkApiRedirect, parseLinkHeader, RedirectException} from 'app/helpers/apiHelpers';
import {isDefaultDistrictSet, setDefaultDistrict} from 'app/actions/sessionActions';
import {updateHistory} from 'app/actions/historyActions';
import {captureSentryException} from 'app/services/sentryLogging';
import MissingAttributeFromApiException from 'app/exceptions/MissingAttributeFromApiException';
import {updateHttpError, updateHttpErrorSubroute} from 'app/components/error/ErrorHandlerActions';

const getPlayerData = player => {
  return api.get(`/profiles/${player}/details`);
};

const getPlayerStats = playerSlug => {
  return api.get(`/profiles/${playerSlug}/stats`);
};

const getMatchesByProfileStations = (profileSlug, profileSeasonId) => {
  return api.get(`${process.env.API_URL}v2/profiles/${profileSlug}/matches?profileSeasonId=${profileSeasonId}`);
};

const streamTypes = {
  news: 'news_published',
  transfer: 'profile_transfer',
  ban: 'player_banned',
  topeleven: 'league_top_eleven',
  injury: 'player_injured',
  matchevent: 'match_event',
};

const getPlayerStream = (slug, categories) => {
  const streamURL = process.env.STREAM_URL;
  if (Object.keys(streamTypes).includes(categories)) {
    const type = streamTypes[categories];
    return api.get(`${streamURL}/profiles/${slug}?limit=18&type=${type}`);
  }

  return api.get(`${streamURL}/profiles/${slug}?limit=18`);
};

const extractActiveSeason = seasons => {
  const restSeasons = seasons && seasons.length ? [...seasons] : [];
  const activeSeasonIndex = restSeasons.indexOf(restSeasons.find(season => season?.team?.competition?.active));
  const activeSeason = activeSeasonIndex >= 0 ? restSeasons.splice(activeSeasonIndex) : [];
  return [activeSeason, restSeasons];
};

const getOrderedListOfProfileSeasons = ({coachRole, playerRole}) => {
  const [activeCoachSeason, remainingCoachSeasons] = extractActiveSeason(coachRole?.seasons);
  const [activePlayerSeason, remainingPlayerSeasons] = extractActiveSeason(playerRole?.seasons);
  return [...activeCoachSeason, ...activePlayerSeason, ...remainingCoachSeasons, ...remainingPlayerSeasons];
};
const retrySettingDefaultDistrict = (listOfSeasons, dispatch) => {
  try {
    return setDefaultDistrict(listOfSeasons[0]?.team?.competition, dispatch);
  } catch (error) {
    if (listOfSeasons.length > 1) {
      const newList = [...listOfSeasons.shift()];
      return retrySettingDefaultDistrict(newList, dispatch);
    }

    if (error instanceof MissingAttributeFromApiException && error.name === MissingAttributeFromApiException.name) {
      captureSentryException(error);
      return null;
    }

    throw error;
  }
};

const trySettingPlayerDefaultDistrict = (entity, dispatch) => {
  const listOfProfileSeasons = getOrderedListOfProfileSeasons(entity);
  if (listOfProfileSeasons.length) {
    return retrySettingDefaultDistrict(listOfProfileSeasons, dispatch);
  }
  return null;
};

function updateProfile(data) {
  return {
    type: 'PROFILE_UPDATE',
    data,
  };
}

function fetchingProfile() {
  return {
    type: 'PROFILE_FETCHING',
  };
}

function setPlayerHeader(dispatch, player) {
  let parent = {
    name: 'ohne Verein',
    entity: '',
  };

  const currentRoleClub = player.currentRole?.team?.club;

  if (currentRoleClub) {
    parent = {
      name: currentRoleClub.name,
      entity: 'club',
      slugs: {
        clubSlug: currentRoleClub.slug,
      },
    };
  }

  dispatch(
    changeHeaderText({
      title: player.firstName + ' ' + player.lastName,
      parent,
    })
  );
}

function fetchPlayerMetaDataSSR(slug) {
  return async function (dispatch, getState) {
    try {
      const response = await getPlayerData(slug);
      const player_1 = response.data;
      checkApiRedirect(player_1.slug, slug, null, null, getState);
      dispatch(updateHistory('undefined', {store: 'PlayerPage', data: {data: player_1, isFetching: false}}));
      dispatch(changeHeaderFollowing(true));
      dispatch(
        changeHeaderEntity({
          entity: 'profile',
          name: `${player_1.firstName} ${player_1.lastName}`,
          slug: player_1.slug,
          id: player_1.id,
        })
      );
      setPlayerHeader(dispatch, player_1);
      if (!isDefaultDistrictSet(getState)) {
        // Return the Promise to make sure further handlers wait until it settles -> ssr rendered page is sent after setDefaultDistrict has finished
        return trySettingPlayerDefaultDistrict(player_1, dispatch);
      }
    } catch (error) {
      if (error instanceof RedirectException) {
        throw error;
      } else {
        const errorData = catchDataError(error);
        if (errorData.status === 410 && errorData.redirectSlug) {
          dispatch(updateHistory('undefined', {store: 'PlayerPage', data: {data: errorData}}));
        } else {
          dispatch(updateHttpError(errorData));
        }
      }
    }
  };
}

function fetchPlayerMetaData(slug, dispatchRedux, getState) {
  return function (dispatch, state) {
    // don't reload data for ssr 410 error-slug due to archived player
    if (state.data.redirectSlug && state.data.redirectSlug !== slug) {
      return;
    }
    if (!state.data.redirectSlug && state?.data?.status && state?.data?.message) {
      return;
    }

    dispatchRedux(changeHeaderFollowing(true));
    // Return on TabBarNavigation
    if (state.data.slug === slug) {
      setPlayerHeader(dispatchRedux, state.data);
      return;
    }
    dispatch(fetchingProfile());

    return getPlayerData(slug)
      .then(response => {
        const player = response.data;
        checkApiRedirect(player.slug, slug);
        dispatch(updateProfile(player));
        dispatchRedux(
          changeHeaderEntity({
            entity: 'profile',
            name: `${player.firstName} ${player.lastName}`,
            slug: player.slug,
            id: player.id,
          })
        );
        setPlayerHeader(dispatchRedux, player);
        if (!isDefaultDistrictSet(getState)) {
          // Return the Promise to make sure further handlers wait until it settles -> ssr rendered page is sent after setDefaultDistrict has finished
          return trySettingPlayerDefaultDistrict(player, dispatch);
        }
      })
      .catch(function (error) {
        if (error instanceof RedirectException) {
          throw error;
        } else {
          const errorData = catchDataError(error);
          if (errorData.status === 410 && errorData.redirectSlug) {
            dispatch(updateProfile(errorData));
          } else {
            dispatchRedux(updateHttpError(errorData));
          }
        }
      });
  };
}

// PLAYER_PROFILE_PAGE
function fetchingStationMatches(data) {
  return {
    type: 'PLAYER_PROFILE_STATION_MATCHES_FETCHING',
    data,
  };
}

function updateStationMatches(data) {
  return {
    type: 'PLAYER_PROFILE_STATION_MATCHES_UPDATE',
    data,
  };
}

function errorStationMatches(data) {
  return {
    type: 'PLAYER_PROFILE_STATION_MATCHES_ERROR',
    data,
  };
}

function updateStationShow(data) {
  return {
    type: 'PLAYER_PROFILE_STATION_SHOW_UPDATE',
    data,
  };
}

function updateStationFilter(data) {
  return {
    type: 'PLAYER_PROFILE_STATION_FILTER_UPDATE',
    data,
  };
}

function fetchPlayerProfileStationMatches(profileSlug, profileSeasonId) {
  return function (dispatch, state) {
    const {matches} = state;
    if (profileSeasonId in matches) {
      return;
    }
    dispatch(fetchingStationMatches({key: profileSeasonId}));
    return getMatchesByProfileStations(profileSlug, profileSeasonId)
      .then(response => dispatch(updateStationMatches({key: profileSeasonId, items: response.data})))
      .catch(function (error) {
        const errorData = catchDataError(error);
        dispatch(errorStationMatches({key: profileSeasonId, error: errorData}));
      });
  };
}

// PLAYER_RANKINGS_PAGE
function updateRankings(data) {
  return {
    type: 'PLAYER_RANKINGS_UPDATE',
    data,
  };
}

function fetchingRankings() {
  return {
    type: 'PLAYER_RANKINGS_FETCHING',
  };
}

function fetchPlayerRankingsSSR(slug) {
  return function (dispatch) {
    return getPlayerStats(slug)
      .then(response => {
        dispatch(
          updateHistory('undefined', {
            store: 'PlayerRankingsPage',
            data: {data: response.data, isFetching: false},
          })
        );
      })
      .catch(function (error) {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchPlayerRankings(slug, dispatchRedux) {
  return function (dispatch, state) {
    if (Object.keys(state.data).length) {
      return;
    }
    dispatch(fetchingRankings());
    return getPlayerStats(slug)
      .then(response => {
        dispatch(updateRankings(response.data));
      })
      .catch(function (error) {
        const errorData = catchDataError(error);
        dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

//PLAYER_NEWS_PAGE
function updateNews(data) {
  return {
    type: 'PLAYER_NEWS_UPDATE',
    data,
  };
}

function errorNews(data) {
  return {
    type: 'PLAYER_NEWS_ERROR',
    data,
  };
}

function fetchingNews() {
  return {
    type: 'PLAYER_NEWS_FETCHING',
  };
}

function overwriteNews(data) {
  return {
    type: 'PLAYER_NEWS_OVERWRITE',
    data,
  };
}

function fetchPlayerNewsSSR(slug, categories) {
  return function (dispatch) {
    dispatch(fetchingNews());
    return getPlayerStream(slug, categories)
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(
          updateHistory('undefined', {
            store: 'PlayerNewsPage',
            data: {items: response.data, nextUrl, categoryFilter: categories, error: null, isFetching: false},
          })
        );
      })
      .catch(function (error) {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}
function fetchPlayerNews(slug, categories, reloading, dispatchRedux) {
  return function (dispatch, state) {
    if (!reloading && state.items.length) {
      return;
    }

    const {isFetching, nextUrl} = state;
    if (isFetching || nextUrl === null) {
      return;
    }
    const fetchFunc = nextUrl ? fetchNextApiCall(nextUrl) : getPlayerStream(slug, categories);
    dispatch(fetchingNews());
    return fetchFunc
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(updateNews({items: response.data, nextUrl, categories}));
      })
      .catch(function (error) {
        const errorData = catchDataError(error);
        nextUrl ? dispatch(errorNews(errorData)) : dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

export {
  fetchPlayerMetaDataSSR,
  fetchPlayerMetaData,
  fetchPlayerProfileStationMatches,
  fetchPlayerRankingsSSR,
  fetchPlayerRankings,
  fetchPlayerNewsSSR,
  fetchPlayerNews,
  overwriteNews,
  updateStationShow,
  updateStationFilter,
};
