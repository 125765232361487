import {getCompetition, getCompetitionStats, getCompetitionHistory, api, fetchNextApiCall} from 'app/services/api';
import {changeHeaderEntity, changeHeaderFollowing, changeHeaderText} from 'app/actions/headerActions';
import {checkCompetitionPathRedirect} from 'app/helpers/actionsHelpers';
import {catchDataError, checkApiRedirect, parseLinkHeader, RedirectException} from 'app/helpers/apiHelpers';
import {trySettingDefaultDistrict} from 'app/actions/sessionActions';
import {updateHistory} from 'app/actions/historyActions';
import {updateHttpError, updateHttpErrorSubroute} from 'app/components/error/ErrorHandlerActions';

const streamTypes = {
  news: 'news_published',
  'fupa-tv': 'playlist_published',
  gallery: 'gallery_published',
  matchday: 'match_day_results',
  matchdelay: 'match_kickoff_changed',
  scorer: 'competition_scorers',
  matchevent: 'match_event',
};

const getCupStream = (cupSlug, seasonSlug = 'current', categories) => {
  const streamURL = process.env.STREAM_URL;
  let url = `${streamURL}/competitions/${cupSlug}/seasons/${seasonSlug}?limit=18`;
  if (Object.keys(streamTypes).includes(categories)) {
    const type = streamTypes[categories];
    url += `&type=${type}`;
  }
  return api.get(url);
};

const getCupGroups = (cupSlug, seasonSlug = 'current') => {
  return api.get(`/competitions/${cupSlug}/seasons/${seasonSlug}/groups`);
};

const getStandingAndMatchesByCupGroup = (cupSlug, seasonSlug = 'current', groupSlug) => {
  return api.get(`/competitions/${cupSlug}/seasons/${seasonSlug}/groups/${groupSlug}`);
};

function updateCup(data) {
  return {
    type: 'CUP_UPDATE',
    data,
  };
}

function setCupHeader(dispatch, cup, seasonSlug) {
  const cupSeasonName = seasonSlug ? `${cup.name} ${cup.season.name}` : cup.name;

  let parent = {
    name: cup.district.name,
    entity: 'district',
    slugs: {
      districtSlug: cup.district.slug,
    },
  };

  dispatch(
    changeHeaderText({
      title: cupSeasonName,
      parent,
    })
  );
}

function fetchCupMetaDataSSR(cupSlug, seasonSlug) {
  return async function (dispatch, getState) {
    dispatch(changeHeaderFollowing(true));
    try {
      const response = await getCompetition(cupSlug, seasonSlug);
      const cup = response.data;
      checkApiRedirect(cup.slug, cupSlug, cup.season, seasonSlug, getState, cup.active);
      checkCompetitionPathRedirect(cup?.category?.id, getState);
      dispatch(updateHistory('undefined', {store: 'CupPage', data: cup}));
      dispatch(changeHeaderEntity({entity: 'competition', name: cup.name, slug: cup.slug, id: cup.id}));
      setCupHeader(dispatch, cup, seasonSlug);
      return trySettingDefaultDistrict(cup, dispatch, getState);
    } catch (error) {
      if (error instanceof RedirectException) {
        throw error;
      } else {
        const errorData = catchDataError(error);
        dispatch(updateHttpError(errorData));
      }
    }
  };
}

function fetchCupMetaData(cupSlug, seasonSlug, dispatchRedux, getState) {
  return function (dispatch, state) {
    dispatchRedux(changeHeaderFollowing(true));
    const cupSeasonSlug = seasonSlug ? cupSlug + '-' + seasonSlug : cupSlug;
    if (Object.keys(state).length > 0 && state.slug) {
      let reduxDataSlug = state.slug;
      reduxDataSlug += seasonSlug ? `-${state.season.slug}` : '';
      if (cupSeasonSlug === reduxDataSlug) {
        setCupHeader(dispatchRedux, state, seasonSlug);
        return;
      }
    }
    if (state?.status && state?.message) {
      return;
    }
    return getCompetition(cupSlug, seasonSlug)
      .then(response => {
        const cup = response.data;
        checkApiRedirect(cup.slug, cupSlug, cup.season, seasonSlug, null, cup.active);
        checkCompetitionPathRedirect(cup?.category?.id);
        dispatch(updateCup(cup));
        dispatchRedux(changeHeaderEntity({entity: 'competition', name: cup.name, slug: cup.slug, id: cup.id}));
        setCupHeader(dispatchRedux, cup, seasonSlug);
        return trySettingDefaultDistrict(cup, dispatchRedux, getState);
      })
      .catch(error => {
        if (error instanceof RedirectException) {
          throw error;
        } else {
          const errorData = catchDataError(error);
          dispatchRedux(updateHttpError(errorData));
        }
      });
  };
}

// CUP_OVERVIEW_PAGE
function fetchingOverview() {
  return {
    type: 'CUP_OVERVIEW_FETCHING',
  };
}

function updateOverview(data) {
  return {
    type: 'CUP_OVERVIEW_UPDATE',
    data,
  };
}

function fetchingRoundMatches(data) {
  return {
    type: 'CUP_OVERVIEW_ROUND_MATCHES_FETCHING',
    data,
  };
}

function updateRoundMatches(data) {
  return {
    type: 'CUP_OVERVIEW_ROUND_MATCHES_UPDATE',
    data,
  };
}

function errorRoundMatches(data) {
  return {
    type: 'CUP_OVERVIEW_ROUND_MATCHES_ERROR',
    data,
  };
}

function updateShow(data) {
  return {
    type: 'CUP_GROUP_SHOW_UPDATE',
    data,
  };
}

const getRecentGroupSlug = data => {
  // Merge all groups from all round into one array
  const groups = [].concat.apply(
    [],
    data.map(round => round.groups)
  );

  let loadGroups = [];

  // Find groups with recent matches
  const recentGroups = groups.filter(group => group.recent_matches_count > 0);

  // if groups with recent matches: use all
  // else: use first element in list
  if (recentGroups.length) {
    recentGroups.map(recentGroup => loadGroups.push(recentGroup.slug));
  } else {
    loadGroups.push(groups[0]?.slug);
  }

  return loadGroups;
};

function fetchCupOverview(cupSlug, seasonSlug, dispatchRedux) {
  return function (dispatch, state) {
    if (state.groups.length) {
      return;
    }
    dispatch(fetchingOverview());
    return getCupGroups(cupSlug, seasonSlug)
      .then(response => {
        dispatch(updateOverview(response.data));
        const recentGroupSlugs = getRecentGroupSlug(response.data);
        const loadCupGroupDetails = groupSlug =>
          fetchCupOverviewRoundMatches(cupSlug, seasonSlug, groupSlug)(dispatch, state);
        const groups = recentGroupSlugs.map(recentGroupSlug => loadCupGroupDetails(recentGroupSlug));
        return Promise.all(groups);
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchCupOverviewRoundMatches(cupSlug, seasonSlug, groupSlug) {
  return function (dispatch, state) {
    const {details} = state;
    const key = groupSlug;
    if (key in details) {
      return;
    }
    dispatch(fetchingRoundMatches({key}));
    return getStandingAndMatchesByCupGroup(cupSlug, seasonSlug, groupSlug)
      .then(response => {
        dispatch(updateRoundMatches({key, items: response.data}));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(errorRoundMatches({key, error: errorData}));
      });
  };
}

function fetchCupOverviewSSR(cupSlug, seasonSlug) {
  return async function (dispatch) {
    dispatch(fetchingOverview());
    try {
      const response = await getCupGroups(cupSlug, seasonSlug);
      const recentGroupSlugs = getRecentGroupSlug(response.data);
      const groups = recentGroupSlugs.map(recentGroupSlug =>
        getStandingAndMatchesByCupGroup(cupSlug, seasonSlug, recentGroupSlug)
          .then(matchesByGroupResponse => {
            return {[recentGroupSlug]: {show: true, items: matchesByGroupResponse.data, isFetching: false, error: null}};
          })
          .catch(error => {
            const errorData = catchDataError(error);
            return {
              [recentGroupSlug]: {
                show: true,
                items: {matches: [], standings: []},
                isFetching: false,
                error: errorData,
              },
            };
          })
      );
      const recentGroups = await Promise.all(groups);
      const details = {};
      recentGroups.map(recentGroup => Object.assign(details, recentGroup));
      dispatch(
        updateHistory('undefined', {
          store: 'CupOverviewPage',
          data: {isFetching: false, groups: response.data, details},
        })
      );
    } catch (cupGroupsError) {
      const cupGroupsErrorData = catchDataError(cupGroupsError);
      dispatch(updateHttpErrorSubroute(cupGroupsErrorData));
    }
  };
}

// CUP_NEWS_PAGE
function updateNews(data) {
  return {
    type: 'CUP_NEWS_UPDATE',
    data,
  };
}

function errorNews(data) {
  return {
    type: 'CUP_NEWS_ERROR',
    data,
  };
}

function fetchingNews() {
  return {
    type: 'CUP_NEWS_FETCHING',
  };
}

function overwriteNews(data) {
  return {
    type: 'CUP_NEWS_OVERWRITE',
    data,
  };
}

function fetchCupNews(cupSlug, seasonSlug, categories, reloading, dispatchRedux) {
  return function (dispatch, state) {
    if (!reloading && state.items.length) {
      return;
    }
    const {isFetching, nextUrl} = state;
    if (isFetching || nextUrl === null) {
      return;
    }

    const fetchFunc = nextUrl
      ? fetchNextApiCall(nextUrl)
      : getCupStream(cupSlug, seasonSlug, categories);
    dispatch(fetchingNews());
    return fetchFunc
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(updateNews({items: response.data, nextUrl, categories}));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        nextUrl ? dispatch(errorNews(errorData)) : dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchCupNewsSSR(cupSlug, seasonSlug, categories) {
  return function (dispatch) {
    dispatch(fetchingNews());
    return getCupStream(cupSlug, seasonSlug, categories)
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(
          updateHistory('undefined', {
            store: 'CupNewsPage',
            data: {items: response.data, nextUrl, categoryFilter: categories, error: null, isFetching: false},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

// CUP_RANKINGS_PAGE
function updateRankings(data) {
  return {
    type: 'CUP_RANKINGS_UPDATE',
    data,
  };
}

function fetchingRankings() {
  return {
    type: 'CUP_RANKINGS_FETCHING',
  };
}

function fetchCupRankingsSSR(cupSlug, seasonSlug) {
  return function (dispatch) {
    return getCompetitionStats(cupSlug, seasonSlug)
      .then(response => {
        dispatch(
          updateHistory('undefined', {
            store: 'CupRankingsPage',
            data: {data: response.data, isFetching: false},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchCupRankings(cupSlug, seasonSlug, dispatchRedux) {
  return function (dispatch, state) {
    if (Object.keys(state.data).length) {
      return;
    }
    dispatch(fetchingRankings());
    return getCompetitionStats(cupSlug, seasonSlug)
      .then(response => {
        dispatch(updateRankings(response.data));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

// CUP_HISTORY_PAGE
function updateCupHistory(data) {
  return {
    type: 'CUP_HISTORY_UPDATE',
    data,
  };
}

function fetchingHistory() {
  return {
    type: 'CUP_HISTORY_FETCHING',
  };
}

function fetchCupHistorySSR(slug) {
  return function (dispatch) {
    return getCompetitionHistory(slug)
      .then(response => {
        dispatch(
          updateHistory('undefined', {
            store: 'CupHistoryPage',
            data: {items: response.data, isFetching: false},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchCupHistory(slug, dispatchRedux) {
  return function (dispatch, state) {
    if (state.items.length) {
      return;
    }
    dispatch(fetchingHistory());
    return getCompetitionHistory(slug)
      .then(response => {
        dispatch(updateCupHistory(response.data));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

export {
  fetchCupMetaDataSSR,
  fetchCupMetaData,
  fetchCupOverviewSSR,
  fetchCupOverview,
  fetchCupOverviewRoundMatches,
  fetchCupNewsSSR,
  fetchCupNews,
  fetchCupRankingsSSR,
  fetchCupRankings,
  fetchCupHistorySSR,
  fetchCupHistory,
  overwriteNews,
  updateShow,
};
